export default class Config {
    static SIO_MAKER_CLOUD = "//sio.makercloud.io";
    static API_MAKER_CLOUD = "//api.makercloud.io";
    static SSO_MAKER_CLOUD = "//sso.makercloud.io";
    static ET_API_MAKER_CLOUD = "//et-api.makercloud.io";
    static LICENSE_API_MAKER_CLOUD = "//license-api.makercloud.io";

    static OPEN_API_ENDPOINT = "https://open-api.makercloud.io/{basePath}/{openApiToken}";

    static IMAGE_MAKER_CLOUD = "//img1.makercloud.io";
    static GOOGLE_CLIENT_ID = "358285562741-dq92co8p1earor9ro7188qpivhstq19p.apps.googleusercontent.com";
    static GOOGLE_SERVICE_ACCOUNT_CLIENT_ID = "112006361333852816485";

    static GOOGLE_API_KEY = "AIzaSyCN_jvYBQkN5nlwSD2yEXhli86Y6C384TM";

}
